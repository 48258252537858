<template>
  <main class="flex flex-col h-full relative">
    <div class="border-opacity-10 fixed z-10 w-full">
      <div class="flex flex-wrap -mb-px text-sm font-medium text-center w-full">
        <div
          class="inline-block grow border-b border-brand-black border-opacity-10"
          id="profile-tab"
          data-tabs-target="#profile"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="true"
        >
          <div class="flex justify-between bg-white rounded py-4 px-8">
            <div class="flex">
              <img
                class="w-8 h-8 object-cover mr-4 rounded-full"
                :src="conversation.profile_url"
                alt="profileImage"
              />
              <div>
                <p class="text-base text-brand-black">{{ conversation.name }}</p>
              </div>
            </div>
            <img
              class="cursor-pointer"
              src="../assets/icons/close.svg"
              alt="Document"
              @click="emit('closeChat')"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="grow overflow-y-scroll overflow-hidden h-[calc(100%-20px)] scrollbar-hide mt-20"
    >
      <template v-for="chat of sendChats" :key="chat.id">
        <BallonChat :chat="chat" />
      </template>
      <div style="padding-bottom: 15rem"></div>
    </div>
    <ChatAction :conversationId="conversation.uuid" />
  </main>
</template>

<script setup>
import { useRoute } from "vue-router";
import { watch, computed, onMounted, ref } from "vue";
import ChatAction from "@/components/ChatAction.vue";
import BallonChat from "@/components/BallonChat.vue";
import { useStore } from "vuex";

const emit = defineEmits(["closeChat"]);

const props = defineProps({
  conversationUuid: { type: String, default: null },
});
const route = useRoute();
const store = useStore();
// const container = ref();

// const scrollToBottom = () => {
//   let el = container.value;
//   el.scrollIntoView(false, {
//     behavior: 'smooth',
//     block: 'end',
//   });
// };

watch(
  () => props.conversationUuid,
  async (uuid) => {
    await store.dispatch("conversation/getConversationMessages", { uuid });
  },
  { immediate: true }
);

const conversation = computed(() => {
  return store.getters["conversation/getSortedConversations"].find(
    (x) => x.uuid === props.conversationUuid
  );
});

const sendChats = computed(() => {
  return store.getters["conversation/getConversationMessages"](
    conversation.value.id
  ).reverse();
});

// onMounted(() => {
//   scrollToBottom;
// });
</script>
