<template>
  <main class="mb-3 flex px-3" :class="chat.left ? '' : 'flex-row-reverse'">
    <div
      :class="chat.left ? 'bg-white text-black rounded-tl-none ' : 'bg-brand-primary text-gray-100 rounded-tr-none '"
      class="break-words p-1 max-w-sm rounded-2xl text-sm"
      id="content"
    >
      <span v-if="chat.type === 'text'" class="px-4 text-lg block">{{ chat.content }}</span>
      <img v-if="chat.type === 'image'" @error="setAltImg" class="rounded-3xl h-auto max-w-xs" :src="chat.url" alt="Photo" />
      <div v-if="chat.type === 'file'" class="rounded-3xl flex flex-col justify-center items-center">
        <div class="py-3 bg-white rounded-3xl">
          <img class="h-auto max-w-xs rounded-3xl" src="/chat-document.jpg" alt="Document" />
        </div>
        <span class="py-1">Document file uploaded</span>
      </div>
    </div>
    <!-- <button @click="myFunction">Scroll</button> -->
  </main>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps({
  chat: {
    type: Object,
    default: null,
  },
});

const setAltImg = (event) => {
  event.target.src = '/chat-broken-image.png';
};
const emits = defineEmits(['newChat']);
</script>
